import { TransformTag } from '@/utils'
import API from '@/api'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)

      if (!data) return
    }

    return this.$path(path, data)
  }
  return [
    {
      component: 'Section',
      condition: get('customFields.project-portal.max-items') === 'none' ? false : true,

      props: {
        width: 'medium',
      },
      blocks: {
        default: [
          {
            component: 'Portal',
            variant: 'project',
            props: {
              api: API,
              hideArrow: true,
              filterMethod: get('customFields.project-portal.filterMethod') || 'default',
              filterCategories:
                get('customFields.project-portal.filterMethod') === 'category'
                  ? get('customFields.project-portal.categories') || []
                  : [],
              theme: get('customFields.project-portal.theme') || 'card',
              maxItems: parseInt(get('customFields.project-portal.max-items')) || 50,
              layout: get('customFields.project-portal.layout'),
              excluded: get('customFields.project-portal.excluded-projects'),
            },
            blocks: {
              top: [
                {
                  component: 'Text',
                  data: {
                    content: TransformTag(get('content')),
                  },
                  props: {
                    style: 'padding-bottom: 3rem;',
                    class: 'text--medium',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      condition: get('customFields.contact-cta.content') || get('customFields.contact-cta.button'),
      props: {
        class: 'contact-cta-bottom ',
        style: 'padding-block: 3.5rem;',
      },

      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              alignItems: 'center',
              class: 'contact-cta-bottom__content',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  condition: get('customFields.contact-cta.content'),
                  data: {
                    content: get('customFields.contact-cta.content'),
                  },
                  props: {
                    textAlign: 'center',
                  },
                },
                {
                  component: 'Button',
                  condition: get('customFields.contact-cta.button'),
                  props: {
                    class: 'contact-cta-bottom__button',
                  },
                  data: {
                    label: get('customFields.contact-cta.button'),
                    to: { name: 'Contact' },
                    theme: 'secondary',
                    tag: 'router-link',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
